<template>
  <el-input v-model.trim="value" :type="showPassword ? 'text' : 'password'" :class="['form-input', isError ? 'is-error' : '']" :placeholder="placeholder">
    <template #suffix>
      <svg-icon v-show="showPassword" class="cp" name="eye" color="#828499" @click="togglePassword"></svg-icon>
      <svg-icon v-show="!showPassword" class="cp" name="close-eye" color="#828499" @click="togglePassword"></svg-icon>
    </template>
  </el-input>
</template>

<script setup lang="ts">
	const props = defineProps({
		modelValue: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: 'Password'
		},
		isError: {
			type: Boolean,
			default: false
		}
	});

	const emit = defineEmits(['update:modelValue']);
	const value = useVModel(props, 'modelValue', emit);

	const showPassword = ref(false);
	const togglePassword = () => {
		showPassword.value = !showPassword.value;
	};
</script>

<style scoped>

</style>
