<template>
  <el-input v-model.trim="value" :maxlength="6" :class="['form-input', isError ? 'is-error' : '']" :placeholder="placeholder">
    <template #suffix>
      <div class="cp w-100px h-24px leading-24px text-center b-l b-solid b-#D2D5E3 c-#6165F6" @click="sendCode">
        {{ countDown ? `${countDown}s` : isSend? 'Resend' : 'Send' }}
      </div>
    </template>
  </el-input>
</template>

<script setup lang="ts">
	const props = defineProps({
		modelValue: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: 'Verification code'
		},
		isError: {
			type: Boolean,
			default: false
		}
	});

	const emit = defineEmits(['update:modelValue', 'sendCode']);
	const value = useVModel(props, 'modelValue', emit);

	const timer = ref();
	const countDown = ref(0);
	const isSend = ref(false);
	const sendCode = () => {
		if (timer.value) {
			return;
		}
		emit('sendCode');
		countDown.value = 60;
		timer.value = setInterval(() => {
			countDown.value--;
			if (countDown.value === 0) {
				isSend.value = true;
				clearInterval(timer.value);
				timer.value = null;
			}
		}, 1000);
	};

	onUnmounted(() => {
		if (timer.value) {
			clearInterval(timer.value);
			timer.value = null;
		}
	});

	defineExpose({
		sendCode
	});
</script>

<style scoped>

</style>
